export function displayRadiatingSector(detectionColorFill) {
  const xmlns = 'http://www.w3.org/2000/svg'
  const svg = document.querySelector(
    'div.leaflet-pane.leaflet-overlay-pane > svg'
  )
  // TODO: Refactor all animations to CSS. this is too complicated for maintanance.
  const existingSvg = svg.getElementById('radiating-animation')
  if (svg && !existingSvg) {
    const defs = document.createElementNS(xmlns, 'defs')
    defs.setAttribute('id', 'radiating-animation')
    // rfZeroDetection
    const radialGradient = document.createElementNS(xmlns, 'radialGradient')
    radialGradient.setAttributeNS(null, 'id', 'rfZeroDetection')
    const stop1 = document.createElementNS(xmlns, 'stop')
    stop1.setAttributeNS(null, 'offset', 0.176414)
    stop1.setAttributeNS(
      null,
      'style',
      `stop-color: ${detectionColorFill}; stop-opacity: 1`
    )
    const animate1 = document.createElementNS(xmlns, 'animate')
    animate1.setAttributeNS(null, 'attributeName', 'offset')
    animate1.setAttributeNS(null, 'dur', '1500ms')
    animate1.setAttributeNS(null, 'values', '0;.8;0')
    animate1.setAttributeNS(null, 'repeatCount', 'indefinite')
    animate1.setAttributeNS(
      null,
      'keySplines',
      '.77 .25 .82 .53; .15 .56 .47 .83'
    )
    stop1.appendChild(animate1)
    const stop2 = document.createElementNS(xmlns, 'stop')
    stop2.setAttributeNS(null, 'offset', '100%')
    stop2.setAttributeNS(
      null,
      'style',
      `stop-color: ${detectionColorFill}; stop-opacity: 0`
    )
    radialGradient.appendChild(stop1)
    radialGradient.appendChild(stop2)
    defs.appendChild(radialGradient)

    const filter1 = document.createElementNS(xmlns, 'filter')
    filter1.setAttributeNS(null, 'id', `blurred1`)
    const feGaussianBlur1 = document.createElementNS(xmlns, 'feGaussianBlur')
    feGaussianBlur1.setAttributeNS(null, 'stdDeviation', 2)
    filter1.appendChild(feGaussianBlur1)
    const filter2 = document.createElementNS(xmlns, 'filter')
    filter2.setAttributeNS(null, 'id', `blurred2`)
    const feGaussianBlur2 = document.createElementNS(xmlns, 'feGaussianBlur')
    feGaussianBlur2.setAttributeNS(null, 'stdDeviation', 3)
    filter2.appendChild(feGaussianBlur2)
    const filter3 = document.createElementNS(xmlns, 'filter')
    filter3.setAttributeNS(null, 'id', `blurred3`)
    const feGaussianBlur3 = document.createElementNS(xmlns, 'feGaussianBlur')
    feGaussianBlur3.setAttributeNS(null, 'stdDeviation', 5)
    filter3.appendChild(feGaussianBlur3)
    const filter4 = document.createElementNS(xmlns, 'filter')
    filter4.setAttributeNS(null, 'id', `blurred4`)
    const feGaussianBlur4 = document.createElementNS(xmlns, 'feGaussianBlur')
    feGaussianBlur4.setAttributeNS(null, 'stdDeviation', 10)
    filter4.appendChild(feGaussianBlur4)
    const filter5 = document.createElementNS(xmlns, 'filter')
    filter5.setAttributeNS(null, 'id', `blurred5`)
    const feGaussianBlur5 = document.createElementNS(xmlns, 'feGaussianBlur')
    feGaussianBlur5.setAttributeNS(null, 'stdDeviation', 15)
    filter5.appendChild(feGaussianBlur5)
    defs.append(filter1)
    defs.append(filter2)
    defs.append(filter3)
    defs.append(filter4)
    defs.append(filter5)

    const linearGradient = document.createElementNS(xmlns, 'linearGradient')
    linearGradient.setAttributeNS(null, 'id', 'rfOutsideDetection')

    const stop3 = document.createElementNS(xmlns, 'stop')
    stop3.setAttributeNS(null, 'offset', '0%')
    stop3.setAttributeNS(
      null,
      'style',
      `stop-color: ${detectionColorFill}; stop-opacity: 0.5`
    )
    const animate2 = document.createElementNS(xmlns, 'animate')
    animate2.setAttributeNS(null, 'attributeName', 'stop-opacity')
    animate2.setAttributeNS(null, 'dur', '1500ms')
    animate2.setAttributeNS(null, 'values', '0.2;.6;0.2')
    animate2.setAttributeNS(null, 'repeatCount', 'indefinite')
    animate2.setAttributeNS(
      null,
      'keySplines',
      '.77 .25 .82 .53; .15 .56 .47 .83'
    )
    stop3.appendChild(animate2)
    linearGradient.appendChild(stop3)
    defs.append(linearGradient)

    svg.appendChild(defs)
  }
}




































































import Vue from 'vue'
import utils from '../../utils'
import { mapGetters } from 'vuex'
import { LCircle, LFeatureGroup, LPolygon } from 'vue2-leaflet'
import {
  isDsxModelDirectional,
  isDsxModelJamming
} from '@/store/modules/rf_sensors/types'
import { getTheme } from '@/components/Map/utils/themes'
import { displayRadiatingSector } from '../radiatingSector.js'
import {
  CONTROLLER_CLASSIFICATION,
  EDetectionState,
  IDetectionContribution,
  IDetectionOnly
} from '@/store/modules/detection/types'

const props = {
  contributor: {
    type: Object as () => IDetectionContribution,
    default: () => {}
  },
  visible: {
    type: Boolean,
    default: true
  },
  detection: {
    type: Object as () => IDetectionOnly,
    default: () => {}
  },
  activeBeam: {
    type: String
  }
}

const SECTOR_MID_OFFSET = 22.5

export default Vue.extend({
  name: 'DsxDetectionSector',
  props,
  inject: ['getSentryLocationOverride'],
  data: () => ({
    watcher: null,
    isDsxModelJamming,
    isDsxModelDirectional
  }),
  components: {
    LFeatureGroup,
    LCircle,
    LPolygon
  },
  computed: {
    ...mapGetters('rf_sensors', ['dsxSensorById', 'allDsxSensorsInSentry']),
    ...mapGetters('cameras', ['allCameras']),
    ...mapGetters('sentries', ['sentriesSet']),
    overrideSentryLocation() {
      return this.getSentryLocationOverride()
    },
    origin() {
      if (this.overrideSentryLocation) {
        return {
          latitude: this.overrideSentryLocation.latitude,
          longitude: this.overrideSentryLocation.longitude,
          direction: this.overrideSentryLocation.direction,
          altitude: this.overrideSentryLocation.altitude
        }
      }
      return {
        latitude: this.sensor.latitude,
        longitude: this.sensor.longitude,
        altitude: this.sensor.altitude,
        direction: this.sensor.direction
      }
    },
    key() {
      return `${this.detection.target_id}:${this.contributor.sensor_id}`
    },
    showDetection() {
      const display720 = this.sensor.show_720_detection
      return !(!display720 && this.allSectorsActive)
    },
    drone(): boolean {
      return this.detection.classification !== CONTROLLER_CLASSIFICATION
    },
    fhssDashArray() {
      if (!this.drone) return this?.theme?.fhss?.dashArray || ''
      return ''
    },
    isSelected() {
      return this.activeBeam === this.key
    },
    fillOpacityDirectional() {
      return (this.contributor.signal_bars / 10 || 0.4) / 2 //fill opacity will change depending on signal strength, this is why don't use the theme
    },
    weight() {
      let unselectedWeight = this.theme?.detection?.lineWeight?.unselected
      if (!this.drone) unselectedWeight += 0.5 //make it obvious that its not a drone detection
      return this.isSelected
        ? this.theme?.detection?.lineWeight?.selected || 2.0
        : unselectedWeight || 0.3
    },
    themeName() {
      return this.$store.state.theme
    },
    theme() {
      return getTheme(this.themeName)
    },
    allSectorsActive() {
      return this.contributor.aoa > 12
    },
    detectionColor() {
      switch (this.state) {
        case EDetectionState.Whitelisted:
          return this.theme.detection.sectorColorWhitelisted
        default:
          return this.theme.detection.sectorColor
      }
    },
    detectionColorFill() {
      switch (this.state) {
        case EDetectionState.Whitelisted:
          return this.theme.detection.sectorFillWhitelisted
        default:
          return this.theme.detection.sectorFill
      }
    },
    sensor() {
      return this.dsxSensorById(this.contributor.sensor_id) || {}
    },
    range() {
      const { reach } = this.sensor || {}
      const { latitude, longitude, direction } = this.origin
      const detectionDir = Math.round((this.contributor.aoa * 180) / Math.PI)
      const min = -SECTOR_MID_OFFSET
      const max = SECTOR_MID_OFFSET
      return reach
        ? utils.createRange({
          reach,
          direction: (direction + detectionDir) % 360,
          min,
          max,
          vertex: [latitude, longitude]
        })
        : []
    },
    fhssRange() {
      const { reach } = this.sensor || {}
      const { latitude, longitude, direction } = this.origin
      const detectionDir = Math.round((this.contributor.aoa * 180) / Math.PI)
      const min = -SECTOR_MID_OFFSET + 8
      const max = SECTOR_MID_OFFSET - 8
      return reach
        ? utils.createRange({
          reach: reach * 0.9,
          direction: (direction + detectionDir) % 360,
          min,
          max,
          vertex: [latitude, longitude]
        })
        : []
    },
    otherRfSensors() {
      return this.dsxSensorsInSentry(
        this.sentriesSet[this.sensor.sentry_id]
      ).filter(sensor => sensor.id !== this.sensor.id)
    },
    cameraRfTracked() {
      return (
        this.allCameras.length &&
        this.allCameras.some(camera => {
          return camera.rf_tracked_target === this.detection.target_id
        })
      )
    }
  },
  methods: {
    onSelect() {
      this.$emit('selectSfRfBeam', this.detection.target_id, this.key)
    },
    scaleBetween(unscaledNum, minAllowed, maxAllowed, min, max) {
      return (
        ((maxAllowed - minAllowed) * (unscaledNum - min)) / (max - min) +
        minAllowed
      )
    },
    // TODO: Handle this on the backend, maybe add a sensor attribute ignoreOutsideLeft...
    anyRfSensorOverlap(side = 'left') {
      return this.otherRfSensors.some(rfsensor => {
        const otherDirection = utils.circularBounds(rfsensor.direction, [
          0,
          360
        ])
        const sensorNeighBourDirection = utils.circularBounds(
          this.origin.direction + (side === 'left' ? -90 : 90),
          [0, 360]
        )
        return sensorNeighBourDirection === otherDirection
      })
    },
    blinkRfDetection(action = true) {
      const detectionRef = [
        this.$refs[`rfds-${this.correlation_key}`],
        this.$refs[`rfdl-${this.correlation_key}`]
      ]
      detectionRef
        .map(dref => dref && dref.mapObject._path)
        .filter(p => p)
        .forEach(path => {
          action
            ? path.classList.add('blinkMap')
            : path.classList.remove('blinkMap')
        })
    }
  },
  mounted() {
    this.$nextTick(() => {
      displayRadiatingSector(this.detectionColorFill)
      if (this.cameraRfTracked) this.blinkRfDetection()
    })
  },
  watch: {
    cameraRfTracked(isTracking) {
      this.blinkRfDetection(isTracking)
    }
  }
})
